import { Action } from '@ngrx/store';
import { AnyModelStr } from 'src/app/shared/types/any-models.types';
import { MenteeRelation } from 'src/app/shared/types/mentee-relations.types';

export const MENTEERELATION_SET_INITIAL = '[MENTEERELATION] Set initial';
export const MENTEERELATION_LOAD = '[MENTEERELATION] Load menteerelation';
export const MENTEERELATION_LOAD_SUCCESS = '[MENTEERELATION] Load menteerelation successful';
export const MENTEERELATION_LOAD_ERROR = '[MENTEERELATION] Load menteerelation failed';
export const UPDATE_MENTEERELATION = '[MENTEERELATION] Update menteerelation';
export const UPDATE_MENTEERELATION_SUCCESS = '[MENTEERELATION] Update menteerelation successful';
export const UPDATE_MENTEERELATION_ERROR = '[MENTEERELATION] Update menteerelation failed';
export const CREATE_MENTEERELATION = '[MENTEERELATION] Create menteerelation';

export class MenteeRelationSetInitial implements Action {
  readonly type = MENTEERELATION_SET_INITIAL;
}

export class MenteeRelationLoad implements Action {
  readonly type = MENTEERELATION_LOAD;
  constructor(public id: string, public mmType: AnyModelStr) {}
}

export class MenteeRelationLoadSuccess implements Action {
  readonly type = MENTEERELATION_LOAD_SUCCESS;
  constructor(public payload: MenteeRelation[]) {}
}

export class MenteeRelationLoadError implements Action {
  readonly type = MENTEERELATION_LOAD_ERROR;
}

export class UpdateMenteeRelation implements Action {
  readonly type = UPDATE_MENTEERELATION;
  constructor(public menteeRelation: Partial<MenteeRelation>, public originator: { id: string; type: AnyModelStr }) {}
}
export class UpdateMenteeRelationSuccess implements Action {
  readonly type = UPDATE_MENTEERELATION_SUCCESS;
  constructor(public payload: MenteeRelation, public originator: { id: string; type: AnyModelStr }) {}
}

export class UpdateMenteeRelationError implements Action {
  readonly type = UPDATE_MENTEERELATION_ERROR;
}

export class CreateMenteeRelation implements Action {
  readonly type = CREATE_MENTEERELATION;
  constructor(public originator: { id: string; type: AnyModelStr }) {}
}

export type Actions =
  | MenteeRelationSetInitial
  | MenteeRelationLoad
  | MenteeRelationLoadSuccess
  | MenteeRelationLoadError
  | UpdateMenteeRelation
  | UpdateMenteeRelationSuccess
  | UpdateMenteeRelationError
  | CreateMenteeRelation;
